/*
@import "@fuse/components/countdown/countdown.theme";
@import "@fuse/components/material-color-picker/material-color-picker.theme";
@import "@fuse/components/search-bar/search-bar.theme";
@import "@fuse/components/shortcuts/shortcuts.theme";
@import "@fuse/components/sidebar/sidebar.theme";
@import "@fuse/components/theme-options/theme-options.theme";
@import "@fuse/components/widget/widget.theme";
*/
@import "../components/countdown/countdown.theme";
@import "../components/material-color-picker/material-color-picker.theme";
@import "../components/search-bar/search-bar.theme";
@import "../components/shortcuts/shortcuts.theme";
@import "../components/sidebar/sidebar.theme";
@import "../components/theme-options/theme-options.theme";
@import "../components/widget/widget.theme";

@import "../layout/components/chat-panel/chat-panel.theme";
@import "../layout/components/toolbar/toolbar.theme";



@mixin fuse-core-theme($theme) {

    // Theming for Fuse core styles
    @include colors-theme($theme);
    @include cards-theme($theme);
    @include material-theme($theme);
    @include page-layouts-theme($theme);
    @include typography-theme($theme);

    // Theming for Fuse components
    @include fuse-countdown-theme($theme);
    @include fuse-search-bar-theme($theme);
    @include fuse-shortcuts-theme($theme);
    @include fuse-sidebar-theme($theme);
    @include fuse-theme-options-theme($theme);
    @include fuse-material-color-picker-theme($theme);
    @include fuse-widget-theme($theme);

    @include chat-panel-theme($theme);
    @include toolbar-theme($theme);


    // Theming for 3rd party components
    @include ngx-datatable-theme($theme);
}

